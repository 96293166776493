import { Button, Drawer, Typography } from "@mui/material";
import React, { createContext, memo, useEffect, useRef, useState} from "react";
import { Colors, Gaps, Variables } from "../../misc/variables";
// ICONS IMPORT
import ClearIcon from '@mui/icons-material/Clear';

import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { FormHandler } from "../../hooks/builder/form";
import store, { clear, removeField, removeFieldsSubtract, setFieldState } from "../store/formStore";
import { useBuilder } from "../../hooks/useBuilder";
import { LoadingButton } from "@mui/lab";
import { useSnackBar } from "./consumers/useSnackBar";
import { AuthHelper } from "../controllers/AuthHelper";
import GzipHelper from "../controllers/GzipHelper";

export const DashboardContext = createContext();


const ReturnElement = (props) => {
  const ref = useRef();
  const element = <div ref={ref}><FormHandler 
  {...props}
  label={props.label} 
  placeholder={`Inserisci ${props.label}`} 
/></div>
  return element 
}

export const DashboardDrawerProvider = ({ children, routerContext }) => {
  const sub = useSelector(state => state.subSelected);

  const [defaultOpen, setDefaultOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [width, setWidth] = useState(Variables.drawerWidth)
  const [elementArray, setElementArray] = useState(undefined)

  // const [width, setWidth] = useState(window.innerWidth);
  let [refs, setRefs] = useState([]);
  let [refresh, setRefresh] = useState(undefined);


  const elements = useSelector(state => state.elements);
  const formObject = useSelector(state => state.request);
  const values = useSelector(state => state.fieldsData);
  const committenti = useSelector(state => state.committenti);
  const [formState, setFormState] = useState({});

  const [customers, setCustomers] = useState([]);

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  // }, [elements])

  const { builder } = useBuilder();
  const importConfig = async (page) => {
    const pageConfig = import(`../config/views/pages/${page.replaceAll('.', '/')}.config`);
    return pageConfig;
  }
  const pushMobileOpen = (param, _width, _title, _elements) => { 
    setDefaultOpen(param);
    setWidth(_width);
    setTitle(_title);
    setElementArray(_elements);
  };

  const { addAlert } = useSnackBar();

  let navigate = null;
  const addRouter = (param) => {navigate = param};
  const getCustomers = async() => {
    const res = await fetch('https://l48oatxneb.execute-api.eu-west-1.amazonaws.com/testnet/api/v1/customer');
    const unzipped = await GzipHelper.unzip(await res.json());
    console.log('COMMITTENTI', unzipped);
    setCustomers([...unzipped]);
  }

  useEffect(() => {
    getCustomers();
  }, [])
  const groupFields = async (...params) => {
    const groupFieldParams = params[1];
    const fieldsToIsolate = _.chunk(params[0].filter(x => x.name.includes(groupFieldParams.identifier))
                                        .map(x => ({
                                            name: x.name.split(`${groupFieldParams.identifier}_`)[1],
                                            value: x.value})), groupFieldParams.subfields.length);
    let cleanFields = {}; 
    params[0].filter(x => !x.name.includes(groupFieldParams.identifier))
              .forEach(x => cleanFields[x.name] = x.value);
    cleanFields[groupFieldParams.name] = fieldsToIsolate;
    return cleanFields;
  }
  const sendRequest = async(url, method, fields, groupFieldByName) => {
    setLoading(true);
   
    try{
      let payload = values
      //CHECK REQUIRED
      for(let element of elements){
        const _element = JSON.parse(element);
        if(_element.required && !payload[_element.fieldName]){
          if(_element.exceptions && _element.exceptions[0].show === 'only' && _element.exceptions[0].committente === values['Committente']?.toUpperCase()){
            alert(`Il campo ${_element.label} è obbligatorio`)
            setLoading(false);
            return;
          }else if(_element.exceptions && _element.exceptions[0].show === 'only' && _element.exceptions[0].committente !== values['Committente']?.toUpperCase()){

          }else{
            alert(`Il campo ${_element.label} è obbligatorio`)
            setLoading(false);
            return;
          }
          
        }

        if(_element.regex && !RegExp(_element.regex).test(values[_element.fieldName])){
          alert(`Il formato di ${_element.label} non è corretto`);
          setLoading(false);
          return;
        }
      }

      if(groupFieldByName){
        payload = await groupFields(payload, groupFieldByName);
      }
      let assign;
      let assignAgency;

      
      //ASSIGNING CONTRACT YEY
      if(url === 'https://l48oatxneb.execute-api.eu-west-1.amazonaws.com/testnet/api/v1/contract/{id}'){
        const res = (await AuthHelper.getUserFromIdToken()).UserAttributes.filter(x => x.Name === 'email')[0].Value.replace('lead4life.it', 'fidasi.it');
        const users = await AuthHelper.getUsers();
        for(let user of users){
          const email = user.Attributes.filter(x => x.Name === 'email')[0].Value;
          if(email === res){
            assign = user.Attributes.filter(x => x.Name === 'sub')[0].Value
            assignAgency = user.Attributes.filter(x => x.Name === 'custom:vendor')[0].Value
          }
        }
      }
      /////////

      if(url === 'https://l48oatxneb.execute-api.eu-west-1.amazonaws.com/testnet/api/v1/lead'){
        console.log(sub);
        assign = sub;
      }
      
      if(method === 'PUT' || title.toLowerCase().includes('modifica')){
        if(url.includes('{id}'))url = url.replace('{id}', payload.id)
        else {
          url = `${url}/${payload.id}`;
          method = 'PUT';
        }
      }

      const _sendRequest = await fetch(url, {
        method: method,
        body: (method === 'POST' || method === 'PUT') ? JSON.stringify({...payload, matricola: sub !== "" ? sub.split('-')[0] : (await AuthHelper.getUserFromIdToken()).UserAttributes.filter(x => x.Name === 'sub')[0].Value.split('-')[0], assigned: assign ? assign : (await AuthHelper.getUserFromIdToken()).UserAttributes.filter(x => x.Name === 'sub')[0].Value, agency: assignAgency ? assignAgency :  (await AuthHelper.getUserFromIdToken()).UserAttributes.filter(x => x.Name === 'custom:vendor')[0].Value.split(';')[0], assignedAgent: assign ? assign : null}) : undefined
      });
      await _sendRequest.json();

      if(_sendRequest.status === 500){
        addAlert({
          message: 'E-mail o telefono già esistente nel database',
          severity: 'error'
        });
        setLoading(false);
        return;
      }
      addAlert({
        message: 'Richiesta inviata con successo',
        severity: 'success'
      })
      setLoading(false);
      setDefaultOpen(false);
      store.dispatch(clear());
      window.setTimeout(() => window.location.reload(), 500);
    }catch(ex){
      setLoading(false);
      addAlert({
        message: 'Errore server',
        severity: 'error'
      })
    }
  }

  const controlledHandler = async (isSignup) => {
    setLoading(true);
    if(isSignup){
      try{
        // email, password, firstName, lastName, vendors, vendor2, roles, company, operation
        const res = await AuthHelper.signUp(values.email, values.password, values.firstName, values.lastName, values.vendor, values.vendor2, values.role, values.company, values.operation);
        setLoading(false);
        addAlert({
          message: 'Utente registrato con successo',
          severity: 'success'
        })
        window.setTimeout(() => window.location.reload(), 500);
      }catch(ex){
        setLoading(false);
        addAlert({
          message: 'Errore server',
          severity: 'error'
        });
      }
      
    }
  }
  
  return (
    <DashboardContext.Provider value={{pushMobileOpen, defaultOpen, addRouter}}>
      {children}
      <Drawer
        sx={{
          width: width,
          flexShrink: 0,
          backgroundColor: `${Colors.primaryColor} !important`,
          '& .MuiDrawer-paper': {
            width: {
              xs: '100%',
              md: width
            },
            boxSizing: 'border-box',
          },
        }}
        PaperProps={{
          sx: {backgroundColor: `#fff !important`, border: 0, boxShadow: '-3px 0 8px 0 rgb(66 91 118 / 21%);'}
        }}
        variant="persistent"
        anchor="right"
        open={defaultOpen}>
          <div style={{background: 'linear-gradient(to bottom right, #9DCEE2, #7256CB)', minHeight: 80, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '2rem', paddingRight: '2rem'}}>
            <Typography variant="h5" fontWeight={700}>{title}</Typography>
            <Button sx={{color: '#fff'}} onClick={() => {setDefaultOpen(false); store.dispatch(clear()); setRefs([])}}>
              <ClearIcon></ClearIcon>
            </Button>
          </div>
          {/*
          BO AGENZIA:
          - DEVE VEDERE TUTTE AGENZIE
          - DEVE POTER METTERE RECUPERATO / DA NON VALIDATO
          - MI DISPIACE GIORGIO ATTENDI
      */}
          <div style={{padding: '2rem'}}>
              {/* {element && Object.keys(element.fields).map(field => <div style={{paddingTop: '1rem'}}>{element.fields[field].element}</div>)} */}
              {elements.map((element, index) => {
                const _element = JSON.parse(element);
                let customizedRegex;
                if(_element.label === 'Plico'){
                  customizedRegex = true;
                }
                return <div key={`form-index-${index}`}>
                  <ReturnElement {..._element} label={_element.label} 
                  error={!customizedRegex ? (values[_element.fieldName]?.length > 0 && _element.regex && !RegExp(_element.regex).test(values[_element.fieldName])) : values[_element.fieldName]?.length !== Number(customers.filter(x => x.name === values['Committente'])[0]?.lunghezza_plico)}
                  helperText={!customizedRegex ? values[_element.fieldName]?.length > 0 && _element.regex && !RegExp(_element.regex).test(values[_element.fieldName]) ? "Formato non corretto." : null  : values[_element.fieldName]?.length !== Number(customers.filter(x => x.name === values['Committente'])[0]?.lunghezza_plico) ? 'Formato non corretto' : null}
                  value={values[_element.fieldName]} sx={{marginBottom: 48}} 
                  filter={_element.filter}
                  onChange={async (e) => {
                  store.dispatch(setFieldState({name: _element.fieldName, value: e.target.value}))
                  // console.log(_element.name);
                  if(e.target.hasOwnProperty('checked') && _element.type !== "checkbox"){ 
                    builder(_element.subforms?.filter(x => x.label === e.target.value)[0].form, _element.subforms?.filter(x => x.label === e.target.value)[0])
                  }
                  
                  if(_element.fieldName === 'Committente'){
                    // const fieldsToRemove = await importConfig('contracts.add');
                    // for(let keyR of Object.keys(fieldsToRemove.default['SuperAdmin'].fields)){
                    //   if(keyR === "Committente"){
                    //     await store.dispatch(removeFieldsSubtract(keyR))
                    //   }
                    // }
                    try{
                      console.log(`contracts.subschema.committenti.${e.target.value.toLowerCase().replaceAll(' ', '_').replaceAll('.', '_')}`);
                      
                      await builder(`contracts.subschema.committenti.${e.target.value.toLowerCase().replaceAll(' ', '_').replaceAll('.', '_')}`);
                    }catch(ex){
                      await builder(`contracts.fallback`);
                    }
                  }
                }} /></div>
              })}
              {/* 4° parametro sono i dati da passare alla richiesta */}
              <LoadingButton type="submit" variant="contained" loading={loading} color="primary" onClick={() => formObject.isControlled ? controlledHandler(true) : sendRequest(formObject.url, formObject.method, null)} fullWidth>Invia</LoadingButton>
          </div>
        
          
      </Drawer>
    </DashboardContext.Provider>
  )
}