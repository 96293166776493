import { Box, Chip, Container, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableCell, TableHead, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { AuthHelper } from "../../controllers/AuthHelper";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import moment from "moment";
import GzipHelper from "../../controllers/GzipHelper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DataTable from "../../components/Table";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';

  import { Bar, Pie } from 'react-chartjs-2';
  import axios from "axios";
import { Cancel } from "@mui/icons-material";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );
 
  
const ListCustomers = () => {
    
    const [role, setRole] = useState(false);
    const [statistic, setStatistic] = useState({});
    const [loading, setLoading] = useState(false);
    const [from, setFrom] = useState(moment().subtract(3, 'months'));
    const [to, setTo] = useState(moment())
    const [months, setMonths] = useState([]);
    const [data, setData] = useState({});
    const [committenti, setCommittenti] = useState([]);
    const [selectedCommittente, setSelectedCommittente] = useState("");
    const [agenzie, setAgenzie] = useState([]);
    const [selectedAgenzia, setSelectedAgenzia] = useState([]);
    const [dataChart, setDataChart] = useState();
    const [ridBollettaTable, setRidBollettaTable] = useState({
      headers: ['Agenzia', 'Percentuale'],
      rid: [],
      bolletta: [],
      max: 30
    });
    const ref = useRef();

    const getRole = async() => {
        const attributes = await AuthHelper.getUserFromIdToken();
        console.log('Attributi dashboard', attributes);
        if(attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value)setRole(attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value)
    }

    const getReport = async () => {
        setLoading(true);
        const userId = await AuthHelper.getUserFromIdToken();
        
        const attribute1 = userId.UserAttributes.filter(x => x.Name === 'custom:vendor')[0]?.Value?.split(';');
        let attribute2 = userId.UserAttributes.filter(x => x.Name === 'custom:vendor2').length > 0 ? userId.UserAttributes.filter(x => x.Name === 'custom:vendor2')[0]?.Value?.split(';') : [];
        // setAgenzie([...attribute1, ...attribute2]);
        console.log('RUOLO DASHBOARD', userId.UserAttributes.filter(x => x.Name === 'custom:roles')[0]?.Value);
        console.log('AGENZIE', selectedAgenzia.length > 0);
        if(userId.UserAttributes.filter(x => x.Name === 'custom:roles')[0]?.Value === 'ADMIN'){
          const res = await fetch(`https://l48oatxneb.execute-api.eu-west-1.amazonaws.com/testnet/api/v1/report?from=${from.format('YYYY-MM-DD')}&to=${to.format('YYYY-MM-DD')}${selectedCommittente !== "" ? `&committente=${selectedCommittente}` : ''}${selectedAgenzia.length > 0 ? `&agency=${selectedAgenzia.join(',')}` : ''}`)
          const json = await res.json();

          setStatistic({...json});
          let datasets = dataChart?.datasets;
          if(datasets){
            datasets[0].data = [
              json.net_ok[0]?.count,
              json.net_ko[0]?.count,
              json.net_working[0]?.count,
            ]
          
            setDataChart({...dataChart, datasets: datasets})
          }
          if(json.rid){
            setRidBollettaTable({...ridBollettaTable, values: [...json.rid], bolletta: [...json.bolletta]})
            console.log('BOLLETTA RID', {...ridBollettaTable, values: [...json.rid], bolletta: [...json.bolletta]})
          }
          if(json.agencies){
              setAgenzie([...json.agencies])
          }
        }else{
          let res;
          console.log('SELECTEDAGENZIA', selectedAgenzia);
          if(!selectedAgenzia || selectedAgenzia.length === 0){
            res = await fetch(`https://l48oatxneb.execute-api.eu-west-1.amazonaws.com/testnet/api/v1/report?from=${from.format('YYYY-MM-DD')}&to=${to.format('YYYY-MM-DD')}${selectedCommittente !== "" ? `&committente=${selectedCommittente}` : ''}&agency=${[...attribute1, ...attribute2].join(',')}`)
          }else if(selectedAgenzia && selectedAgenzia !== ""){
            res = await fetch(`https://l48oatxneb.execute-api.eu-west-1.amazonaws.com/testnet/api/v1/report?from=${from.format('YYYY-MM-DD')}&to=${to.format('YYYY-MM-DD')}${selectedCommittente !== "" ? `&committente=${selectedCommittente}` : ''}&agency=${selectedAgenzia}`)
          }
          
          const json = await res.json();

          setStatistic({...json});

          let _datachart = {...dataChart};
          if(_datachart.datasets){
            _datachart.datasets[0].data = [
              json.net_ok[0]?.count,
              json.net_ko[0]?.count,
              json.net_working[0]?.count,
            ]
          }
          if(json.agencies ){
            if(role !== 'OPERATORE BO' || role !== 'ADMIN'){
              setAgenzie([...json.agencies.filter(x => attribute1.includes(x.agency) || attribute2.includes(x.agency))]);
            }
          }
          if(json.rid){
            setRidBollettaTable({...ridBollettaTable, values: [...json.rid], bolletta: [...json.bolletta]})
            console.log('BOLLETTA RID', {...ridBollettaTable, values: [...json.rid], bolletta: [...json.bolletta]})
          }
          setDataChart({..._datachart});
          
        }
        setLoading(false);

    }
    useEffect(() => {
        getRole();
        getReport();
        getCommittenti();
    }, [])
    const options = {
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Grafico andamento contratti per mese',
          },
        },
      };

    const getCommittenti = async() => {
      const res = await axios.get('https://l48oatxneb.execute-api.eu-west-1.amazonaws.com/testnet/api/v1/customer');
      const unzipped = await GzipHelper.unzip(res.data);
      setCommittenti([...unzipped.filter(x => x.type !== 'Telefonia')]);
    }

    const StatisticCard = (props) => (
        <Card sx={{minWidth: 180, backgroundColor: 'rgb(46, 63, 80)', mt: 3}}>
            <CardContent>
                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                Stato: {props.status_maurizon}
                </Typography>
                <Typography variant="h5" component="div">
                {props?.count}
                </Typography>
            </CardContent>
            <CardActions>
                {/* <Button size="small">Vai ai contratti</Button> */}
            </CardActions>
        </Card> 
    )
    return (<Container sx={{
          md: {
            paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem', textAlign: 'center'
          }
        }}>
        <h1 style={{paddingBottom: 24, paddingTop: 12}}>Statistiche e Reportistica OXYGEN</h1>
        {role && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
              <DatePicker value={from !== '' ? from : new Date()} onChange={(e) => {setFrom(e);}} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, mr: 2}}/>} />
          </LocalizationProvider>
          -
          <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
              <DatePicker value={to !== '' ? to : new Date()} onChange={(e) => {setTo(e);}} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, ml: 2}}/>} />
          </LocalizationProvider>
          <FormControl>
            <InputLabel sx={{marginLeft: '24px'}} id="select-committente-label">Seleziona committente</InputLabel>
            <Select
                labelId="select-committente-label"
                id="select-committente"
                value={selectedCommittente}
                size="small"
                label="Seleziona committente"
                onChange={(e) => {
                  setSelectedCommittente(e.target.value)
                }}
                sx={{color: '#000', width: 300, ml: 3}}
              >
                <MenuItem value={null}>Nessuna</MenuItem>
                {committenti.filter(x => x['Visibile contratto'] === 'S').map(x => {
                return (
                      <MenuItem value={x.name}>{x.name}</MenuItem>
                    // <Button variant="contained" color="secondary" sx={{ml: 2, mb: 2}} onClick={() => setSelectedCommittente(x.name)}>{x.name}</Button>
                  
                )})}
              </Select>
          </FormControl>
          <FormControl>
            <InputLabel sx={{marginLeft: '24px'}} id="select-committente-label">Seleziona agenzia</InputLabel>
            <Select
                labelId="select-committente-label"
                id="select-committente"
                value={selectedAgenzia}
                size="small"
                label="Seleziona agenzia"
                onChange={(e) => {
                  setSelectedAgenzia([...typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value])
                }}
                multiple
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} 
                      onDelete={(e) => {
                        setSelectedAgenzia([...selectedAgenzia.filter(x => x !== value)]);
                      }}
                      deleteIcon={
                        <Cancel
                          onMouseDown={(event) => event.stopPropagation()}
                          htmlColor="#7f7f7f"
                        />
                      } label={value} sx={{color: '#000'}} />
                    ))}
                  </Box>
                )}
                sx={{color: '#000', width: 300, ml: 3}}
              >
                {agenzie.sort((a,b) => b.agency - a.agency).filter(agency => !agency.agency.includes(";") && !agency.agency.includes(",") && /^[A-Z0-9\s&'()._]+$/.test(agency.agency)).map(x => {
                return (
                      <MenuItem value={x.agency}>{x.agency}</MenuItem>
                    // <Button variant="contained" color="secondary" sx={{ml: 2, mb: 2}} onClick={() => setSelectedCommittente(x.name)}>{x.name}</Button>
                  
                )})}
              </Select>
          </FormControl>
          <Button onClick={() => {getReport()}}>Aggiorna</Button>
      </div>}
        {role && (role !== 'AGENTE') && Object.keys(statistic)?.length > 0 && <Grid container mt={2} rowSpacing={1} columnGap={3}>
                <Grid xs={12} md={2}>
                    <StatisticCard status_maurizon={'TOTALI'} count={statistic.totali[0]?.count} />
                </Grid> 
                <Grid xs={12} md={2}>
                    <StatisticCard status_maurizon={'CARICATI'} count={statistic.caricati[0]?.count} />
                </Grid> 
                <Grid xs={12} md={2}>
                    <StatisticCard status_maurizon={'DA CARICARE'} count={statistic.da_caricare[0]?.count} />
                </Grid> 
                <Grid xs={12} md={2}>
                    <StatisticCard status_maurizon={'KO'} count={statistic.ko[0]?.count} />
                </Grid> 
                <Grid xs={12} md={2}>
                    <StatisticCard status_maurizon={'NON VALIDATI'} count={statistic.non_validato[0]?.count} />
                </Grid> 
            {/* <Grid xs={10} mt={3}>
                    <Line style={{width: 320}} options={options} data={data} />
            </Grid> */}
             {role === 'OPERATORE BO' || role === 'ADMIN' && <Grid xs={12} md={6} mt={6} gap={2}>
             <><h3>Top 5 Agenzie</h3>
                    <Bar options={
                      {
                        responsive: true,
                        plugins: {
                          legend: {
                            position: 'top',
                          },
                          title: {
                            display: false,
                          },
                        },
                        scales: {
                          x: {
                            stacked: true,
                          },
                          y: {
                            stacked: true,
                          },
                        },
                      }
                    } data={{
                      labels: statistic.top5_agencies.map(x => x.agency),
                      datasets: [
                        {
                          label: 'Contratti KO',
                          data: statistic.top5_agencies.map(x => statistic.top5_agencies_ko.filter(y => y.agency === x.agency)[0]?.count),
                          backgroundColor: '#880808'
                        },
                        {
                          label: 'Contratti caricati',
                          data: statistic.top5_agencies.map(x => x?.count),
                          backgroundColor: '#33b864'
                        },
                       
                      ]
                    }}/></>
                </Grid> }
                <Grid xs={12} md={4} sx={{ml: 8.5, pt: 6, pb: 8, mr: role === 'OPERATORE BO' || role === 'ADMIN' ? 0 : 24}}>
                  <h3>Contratti netti stato finale</h3>
                  <Pie data={{
                      labels: ['OK', 'KO', 'IN LAVORAZIONE'],
                      datasets: [
                        {
                          label: 'Contratti netti',
                          data: [statistic.net_ok[0]?.count, statistic.net_ko[0]?.count, statistic.net_working[0]?.count],
                          backgroundColor: [
                            '#33b864',
                            '#880808',
                            '#ffbf00',
                          ],
                          borderColor: [
                            '#33b864',
                            '#880808',
                            '#ffbf00',
                          ],
                          borderWidth: 1,
                        },
                      ],
                  }} />
                 
                </Grid>
                <Grid xs={12} md={6}>
                    <StatisticCard status_maurizon={'RID GLOBALE'} count={`${statistic.rid_globale[0]?.percentuale_rid?.replace('.', ',')}%`} />
                </Grid> 
                <Grid xs={12} md={5}>
                    <StatisticCard status_maurizon={'BOLLETTA GLOBALE'} count={`${statistic.bolletta_global[0]?.percentuale_bw?.replace('.', ',')}%`} />
                </Grid> 
                <Grid xs={12} md={6}>
                <h2>Rid</h2>
                    {!loading && ridBollettaTable.values &&  <DataTable 
                      ref={ref}
                      getRowId={(row) => row.agency} 
                      noToolbar={true}
                      data={ridBollettaTable.values} 
                      columns={[
                        { field: 'agency', headerName: 'Agenzia', width: 200, headerClassName: 'data-grid-header'},
                        { field: 'risultato', headerName: 'Percentuale', width: 200, headerClassName: 'data-grid-header', valueGetter: (params) => `${Number(params.row['risultato']).toFixed(1)}%`},
                      ]}
                      onClick={{action: 'navigate', path: ':id'}}
                        initialState={{
                          sorting: {
                            sortModel: [{field: 'createdAt', sort: 'desc'}]
                          }
                        }}
                    />}
                </Grid>
                <Grid xs={12} md={5}>
                    <h2>Bolletta Web</h2>
                    {!loading && <DataTable 
                      ref={ref}
                      getRowId={(row) => row.agency} 
                      noToolbar={true}
                      data={ridBollettaTable.bolletta} 
                      columns={[
                        { field: 'agency', headerName: 'Agenzia', width: 200, headerClassName: 'data-grid-header'},
                        { field: 'risultato', headerName: 'Percentuale', width: 200, headerClassName: 'data-grid-header', valueGetter: (params) => `${Number(params.row['risultato']).toFixed(1)}%`},
                      ]}
                      onClick={{action: 'navigate', path: ':id'}}
                        initialState={{
                          sorting: {
                            sortModel: [{field: 'createdAt', sort: 'desc'}]
                          }
                        }}
                    />}
                </Grid>
        </Grid>}

        
    </Container>);
}

export default ListCustomers;